<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BTable,
    VBTooltip
} from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
// import vSelect from 'vue-select';
import TableActionBtns from '@/components/TableActionBtns';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';

export default {
    name: 'OrganizationsSingle',
    components: {
        TableActionBtns,
        PageTitle,
        BButton,
        BModal,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BCol,
        BRow,
        BTable
        // vSelect
    },
    mixins: [CRUDPageMixin],
    directives: {
        'b-tooltip': VBTooltip
    },
    data: () => ({
        organization: {
            name: ''
        },
        surveys: [],
        dataForModal: {
            name: {
                uz: '',
                ru: '',
                en: ''
            },
            position: '',
            // dependent_survey_id: null,
            is_draft: false,
            is_dependent: false,
            start_date: null,
            end_date: null
        }
    }),
    computed: {
        surveysTableHeader() {
            return [
                {
                    key: 'id',
                    label: 'Id'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title'),
                    formatter: (name) => checkLang(name)
                },
                // {
                //     key: 'dependent_survey',
                //     label: 'Dependent survey',
                //     formatter: (name) => (name ? name : '-')
                // },
                // {
                //     key: 'isDependent',
                //     label: 'Is Dependent'
                // },
                {
                    key: 'isDraft',
                    label: 'Is draft'
                },
                {
                    key: 'start_date',
                    label: this.$t('titles.start_date')
                },
                {
                    key: 'end_date',
                    label: this.$t('titles.end_date')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getOrganization() {
            const { data } = await api.organizations.getSingleOrganizationApi(
                this.$route.params.id
            );
            this.organization = data.result;
        },

        async getSurveys() {
            const { data } = await api.surveys.getSurveysApi(this.$route.params.id);
            this.surveys = data.result;
        },

        async create() {
            const { data } = await api.surveys.createSurveyApi(
                this.$route.params.id,
                this.dataForModal
            );
            this.surveys.push(data.result);

            this.dataForModal = {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                position: '',
                is_draft: false,
                is_dependent: false,
                start_date: null,
                end_date: null
            };
            this.$toast.success(this.$t('success.added'));
        },

        async update() {
            const { data } = await api.surveys.updateSurveyApi(
                this.$route.params.id,
                this.updatingItem.id,
                this.dataForModal
            );
            this.updateDataToNewData(data.result);
        },

        async deleteSurvey(id, index) {
            try {
                await api.surveys.deleteSurveyApi(this.$route.params.id, id);
                this.surveys.splice(index, 1);
                this.$toast.success(this.$t('success.deleted'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    },
    mounted() {
        this.getOrganization();
        this.getSurveys();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.organization') }}: {{ organization.name }}</template>
            <template v-if="hasAccess('vote_surveys', 'create')" #button>
                <b-button class="ml-auto" variant="success" @click="isOpenModal = true">
                    {{ $t('add.survey') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <b-table
                        :fields="surveysTableHeader"
                        :items="surveys"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <!--                        <template #cell(dependent_survey)="{ item }">-->
                        <!--                            <template v-if="item.dependent_survey">-->
                        <!--                                {{ item.dependent_survey.name }}-->
                        <!--                            </template>-->
                        <!--                        </template>-->
                        <template #cell(isDependent)="{ item }">
                            <feather-icon
                                :class="[
                                    'stroke-current',
                                    generateIconColorToBoolean(item.is_dependent)
                                ]"
                                :icon="generateIconToBoolean(item.is_dependent)"
                            />
                        </template>
                        <template #cell(isDraft)="{ item }">
                            <feather-icon
                                :class="[
                                    'stroke-current',
                                    generateIconColorToBoolean(item.is_draft)
                                ]"
                                :icon="generateIconToBoolean(item.is_draft)"
                            />
                        </template>
                        <template #cell(actions)="{ item, index }">
                            <div class="d-flex justify-content-end">
                                <b-button
                                    v-if="hasAccess('votes', 'view')"
                                    v-b-tooltip.hover.top="`${$t('button.open')}`"
                                    :to="{
                                        name: 'survey-show',
                                        params: {
                                            organizationID: $route.params.id,
                                            surveyID: item.id
                                        }
                                    }"
                                    class="btn-icon"
                                    variant="flat-dark"
                                >
                                    <feather-icon icon="ArrowRightCircleIcon" />
                                </b-button>
                                <table-action-btns
                                    :delete-access="{
                                        section: 'vote_surveys',
                                        permission: 'delete'
                                    }"
                                    :delete-handler="deleteSurvey"
                                    :index="index"
                                    :item="item"
                                    :update-access="{
                                        section: 'vote_surveys',
                                        permission: 'update'
                                    }"
                                    @openModal="openModalToUpdate"
                                />
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="false"
            :title="$t('add.survey')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    SURVEY NAME    -->
                    <b-col cols="6">
                        <label>{{ $t('titles.title') }} (uz)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="'Название (uz)'"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} (uz)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SURVEY NAME    -->
                    <b-col cols="6">
                        <label>{{ $t('titles.title') }} (ru)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="'Название (ru)'"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} (ru)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    SURVEY NAME    -->
                    <b-col cols="6">
                        <label>{{ $t('titles.title') }} (en)</label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="'Название (en)'"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} (en)`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col class="mb-1" cols="6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.position')}`"
                            rules="required"
                        >
                            <label>
                                {{ $t('titles.position') }}
                            </label>
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    :placeholder="`${$t('enter.position')}`"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    START DATE    -->
                    <b-col cols="6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.start_date')"
                            rules="required"
                        >
                            <label>{{ $t('titles.start_date') }}</label>
                            <b-form-datepicker
                                v-model="dataForModal.start_date"
                                class="mb-1"
                                style="z-index: 99"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    END DATE    -->
                    <b-col cols="6">
                        <ValidationProvider v-slot="{ errors }" :name="`${$t('titles.end_date')}`">
                            <label>{{ $t('titles.end_date') }}</label>
                            <b-form-datepicker
                                v-model="dataForModal.end_date"
                                :state="errors.length > 0 ? false : null"
                                class="mb-1"
                                style="z-index: 99"
                            />
                        </ValidationProvider>
                    </b-col>

                    <!--    DEPENDENT SURVEY    -->
                    <!--                    <b-col cols="12">-->
                    <!--                        <label> Dependent survey </label>-->
                    <!--                        <b-form-group>-->
                    <!--                            <v-select-->
                    <!--                                v-model="dataForModal.dependent_survey_id"-->
                    <!--                                :menu-props="{-->
                    <!--                                    closeOnContentClick: false-->
                    <!--                                }"-->
                    <!--                                :options="surveys"-->
                    <!--                                :placeholder="$t('choose.type')"-->
                    <!--                                :reduce="(type) => type.uuid"-->
                    <!--                                :searchable="false"-->
                    <!--                                class="select-size-lg"-->
                    <!--                                label="name"-->
                    <!--                            />-->
                    <!--                        </b-form-group>-->
                    <!--                    </b-col>-->

                    <!--    IS DEPENDENT    -->
                    <!--                    <b-col cols="6">-->
                    <!--                        <label></label>-->
                    <!--                        <b-form-checkbox v-model="dataForModal.is_dependent">-->
                    <!--                            Is dependent-->
                    <!--                        </b-form-checkbox>-->
                    <!--                    </b-col>-->

                    <!--    IS DRAFT    -->
                    <b-col cols="6">
                        <label></label>
                        <b-form-checkbox v-model="dataForModal.is_draft">
                            Is draft
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="modalSubmit">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped></style>
